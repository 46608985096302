import React, { useState, useEffect } from 'react'
import { Dropdown, Spinner } from "react-bootstrap"
import axios from 'axios';
import { SERVER_URL } from '../../../../configs';
import { Form } from "react-bootstrap";

const AffiliatesDropdown = ({ affiliateId, handleAffiliateSelection }) => {

  const [loading, setLoading] = useState(false)
  const [affiliateDropdownShown, setAffiliateDropdownShown] = useState(false)
  const [affiliateText, setAffiliateText] = useState('')
  const [affiliates, setAffiliates] = useState([])
  const [filteredAffiliates, setFilteredAffiliates] = useState([])

  const handleAffiliateBlur = () => {
    setTimeout(function () {
      setAffiliateDropdownShown(false)
    }, 500)
  }

  const handleSelection = (_affiliate) => {
    setAffiliateText(_affiliate?.companyName ?? '')
    handleAffiliateSelection(_affiliate)
  }

  const loadAffiliates = () => {

    setLoading(true)
    axios.get(`${SERVER_URL}/api/Affiliate/GetAffiliates`).then((res) => {
      setAffiliates(res.data)
    }).finally(() => {
      setLoading(false)
    })

  }

  useEffect(() => {
    loadAffiliates()
  }, [])
  
  useEffect(() => {
    setFilteredAffiliates(
      affiliates.filter(a =>
        Object.values(a).some(value =>
          value && value.toString().toLowerCase().includes(affiliateText.toLowerCase())
        )
      )
    );

  }, [affiliates, affiliateText])
  
  useEffect(() => {
    setFilteredAffiliates(affiliates.filter(a => !affiliateId || a.id === affiliateId))
    var found = affiliates.find(a => a.id === affiliateId);
    if (found) {
      setAffiliateText(found.companyName)
    }
  }, [affiliates, affiliateId])
  
  return (
    <>
      <Form.Group>
        <Form.Control
          type="text"
          name="affiliateId"
          placeholder="Partner"
          onClick={() => setAffiliateDropdownShown(true)}
          onBlur={handleAffiliateBlur}
          value={affiliateText}
          autoComplete="off"
          onChange={e => setAffiliateText(e.target.value)}
        />
      </Form.Group>
      <Dropdown show={affiliateDropdownShown} style={{ display: "block" }}>
        <Dropdown.Menu style={{ width: "100%" }} className="jobs-dropdown">
          <Dropdown.Item key={0} onClick={() => handleSelection(null)}>-</Dropdown.Item>
          {loading ? <div style={{ width: "fit-content", margin: "auto" }}>
            <Spinner animation="grow" />
          </div> : filteredAffiliates.map((affiliate) => {
            return <Dropdown.Item key={affiliate.id} onClick={() => handleSelection(affiliate)}>{affiliate.companyName}</Dropdown.Item>
          })
          }
        </Dropdown.Menu>
      </Dropdown >
    </>
  )
}

export default AffiliatesDropdown;