import axios from "../utils/axios";

export function getTicketGroupById(ticketGroupId) {
  const params = new URLSearchParams({ ticketGroupId });
  return axios.get(`/TicketGroup/GetTicketGroupById?${params}`);
}

export function changeTicketGroupStatus(ticketGroupStatus) {
  return axios.post("/TicketGroup/ChangeTicketGroupStatus", ticketGroupStatus);
}

export function updateTicketGroupContact(ticketGroupId, customerContactId) {
  return axios.post("/TicketGroup/UpdateTicketGroupContact", { ticketGroupId, customerContactId });
}

export function updateTicketGroupAffiliateInfo(ticketGroupId, affiliateInfo) {
  return axios.post("/TicketGroup/UpdateTicketGroupAffiliateInfo", { ticketGroupId, ...affiliateInfo });
}

export function updateGeneralNotes(ticketGroupId, note) {
  const searchParams = new URLSearchParams({ ticketGroupId, note });
  return axios.post(`/TicketGroup/UpdateGeneralNotes?${searchParams}`);
}

export function deleteTicketGroup(ticketGroupId) {
  return axios.post(
    `/TicketGroup/DeleteTicketGroup?ticketGroupId=${ticketGroupId}`
  );
}

export function changeDueDate(ticketId, dueDate) {
  const searchParams = new URLSearchParams({ ticketId, dueDate });
  // NOTE: uncomment lines below and delete lines above for "DD calendar new feature"
// export function changeDueDate(ticketId, dueDate) {
//   const searchParams = new URLSearchParams({ ticketId, dueDate });
  return axios.post(`/Ticket/ChangeDueDate?${searchParams}`);
}

export function changeInternalDueDate(ticketId, dueDate) {
  const searchParams = new URLSearchParams({ ticketId, dueDate });
  return axios.post(`/Ticket/ChangeInternalDueDate?${searchParams}`);
}

export function getDueDateCalendarData(year, month, includeCompleted = false) {
  const searchParams = new URLSearchParams({ year, month, includeCompleted });
  return axios.get(`/TicketGroup/GetDueDateCalendarData?${searchParams}`);
}

export function getDueDateCalendarResidentialData(year, month, includeCompleted = false) {
  const searchParams = new URLSearchParams({ year, month, includeCompleted });
  return axios.get(
    `/TicketGroup/GetDueDateCalendarResidentialData?${searchParams}`
  );
}

export function reInitializeTeamsAndChannelsV2(ticketGroupId) {
    return axios.post(`/MicrosoftTeams/ReInitializeTeamsAndChannelsV2?ticketGroupId=${ticketGroupId}`, {});
}
